.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(350deg, #11c4e2, #ef3aa5);
  min-height: 100px;
  padding: 20px;
  margin-top: 110px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(1px);
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}
.wavePink {
  position: absolute;
  top: -98px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../assets/wave-pink3.svg");
  filter: blur(1px);
  background-size: 1000px 100px;
}
.waveBlue {
  position: absolute;
  top: -98px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../assets/wave-blue3.svg");
  filter: blur(1px);
  background-size: 1000px 100px;
}

.wavePink#wave1 {
  z-index: 1000;
  opacity: 0.8;
  bottom: 0;
  animation: animateWaves 20s linear infinite;
}

.waveBlue#wave2 {
  z-index: 999;
  opacity: 0.7;
  bottom: 10px;
  animation: animate 18s linear infinite !important;
}

.waveBlue#wave3 {
  z-index: 1000;
  opacity: 0.3;
  bottom: 15px;
  animation: animateWaves 13s linear infinite;
}

.wavePink#wave4 {
  z-index: 999;
  opacity: 0.3;
  bottom: 20px;
  animation: animate 15s linear infinite;
}

@keyframes animateWaves {
  0% {
    /* background-color: #11c4e2; */
    background-position-x: 1000px;
  }
  100% {
    /* background-color: #ef3aa5; */
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    /* background-color: #ef3aa5;  */
    background-position-x: -1000px;
  }
  100% {
    /* background-color: #ef3aa5; */
    background-positon-x: 0px;
  }
}