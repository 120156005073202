* {
  margin: 0;
  padding: 0;
  font-family: "Times New Roman", Times, serif;
  box-sizing: border-box;
}
:root {
  --primaryColor: #11c4e2;
  --secondaryColor: #ef3aa5;
  --textColor: #000000;
}
html,
body {
  background-color: var(--secondaryColor);
  background-image: radial-gradient(#d1d1d8, #f7f7ff 80%);
  width: 100%;
  margin: 0;
  height: 100%;
}

header {
  background-image: linear-gradient(350deg, #11c4e2a6, #ef3aa5a6);
  backdrop-filter: blur(15px);
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.42);
  z-index: 100;
}

header::after {
  content: "";
  display: table;
  clear: both;
}

/* navbar */
/* -------------------------------------------------- */
.navBar {
  width: 80%;
  margin: 0 auto;
}
.brand {
  float: left;
  padding: 5px 0;
  /* color: #fff; */
}
nav {
  float: right;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav li {
  display: inline-block;
  margin-left: 70px;
  padding-top: 1rem;
  position: relative;
}
nav a {
  color: #444;
  text-decoration: none;
  text-transform: uppercase;
}
nav a:hover {
  color: black;
}
nav a:before {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background-color: rgb(255, 255, 255);

  position: absolute;
  top: 0;
  width: 0%;

  transition: all ease-in-out 200ms;
}
nav a:hover::before {
  width: 100%;
}
header .nav-btn {
  float: right;
  padding: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 1.5rem;
}
/* -------------------------------------------- */

/* ============================================= */
/* elements */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
}
#about {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
/* hero section */
/* ============================================= */
#hero {
  min-height: 100vh;
  width: 100vw;
  background-image: radial-gradient(#d5d5d5, #f7f7ff 80%);
}
.hello {
  align-self: flex-start;
  font-size: 8rem;
  margin-left: 9vw;
  margin-top: -150px;
}
.littleText {
  display: none;
}
.textDiv {
  /* margin: 10px; */
  position: absolute;
  top: 82%;
  left: 45%;
  transform: perspective(1000px) translate(-50%, -50%);
  transform: skewY(15deg);
  transition: 0.5s;
  z-index: 99;
}
.textDiv:hover {
  transform: perspective(1000px) translate(-50%, -50%);
  transform: skewY(0deg);
}
.bigText span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  margin-left: 100px;
  text-transform: uppercase;
  font-size: 7rem;
  color: #000;
  transform-style: preserve-3d;
  transition: 0.8s;
}
.bigText span:nth-child(1) {
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
}
.bigText span:nth-child(2) {
  background: #11c4e2;
  background: linear-gradient(to right, #11c4e2 0%, #ef3aa5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  transform: translate(-50%, -50%) skewX(-60deg);
  left: -10px;
  clip-path: polygon(0 45%, 100% 45%, 100% 55%, 0 55%);
}
.bigText span:nth-child(3) {
  transform: translate(-50%, -50%) skewY(0deg);
  left: -20px;
  clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
}
.bigText:hover span:nth-child(2),
.bigText:hover span:nth-child(3) {
  transform: translate(-50%, -50%) skewX(0deg);
  left: 0;
  color: #000;
}

.hero {
  display: flex;
}
.heroImg {
  max-width: 20vw;
  margin: 1rem;
  box-shadow: 0px 8px 41px 23px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 0px 8px 41px 23px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 0px 8px 41px 23px rgba(0, 0, 0, 0.54);
}
.title {
  margin: 10px;
  font-size: 4rem;
}
.subtitle {
  align-self: flex-start;
  margin-left: 9vw;
  font-size: 2rem;
}
/* -------------------------------------------- */
/* ============================================= */
/* Projects */
h2 {
  font-size: 2rem;
  margin: 10px;
}
.myWork {
  display: block;
  font-size: 2rem;
}
.projectImg {
  max-width: 20vw;
  margin: 10px;
  transition: 0.5s;
  border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;

  box-shadow: 1px 3px 47px 15px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 1px 3px 47px 15px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 1px 3px 47px 15px rgba(0, 0, 0, 0.37);
}
.projectImg:hover {
  transform: translateY(-10px);
}
.projectList {
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
}

.tooltip .tooltipText {
  visibility: hidden;
  max-width: 33vw;
  background-color: black;
  color: #fff;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltipText {
  visibility: visible;
}
/* Contact Me */
/* =============================================== */
.link {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  color: #000;
}

/* hex codes */
/* #88D18A */

/* breakpoint */
/* -------------------------------------------- */
@media only screen and (max-width: 1020px) {
  .textDiv {
    display: none;
  }
  .littleText {
    display: block;
    text-shadow: -1px 3px 6px rgba(0, 0, 0, 0.6);
    font-size: 4rem;
  }
}
@media only screen and (max-width: 820px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
    background-color: var(--secondaryColor);
  }
  nav li {
    display: flex;
    padding: 1.5rem 1.5rem;
    font-size: 1.5rem;
    outline: #000000;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}
@media only screen and (max-width: 515px) {
  header nav {
    background-color: var(--primaryColor);
  }
}
